import React from "react"
import { Link } from "gatsby"


const ImpressumContent = () => {
  return (
    <section className="terms-of-service-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="terms-of-service-content">
              <p><i>Das Impressum wurde zum letzten Mal am 30. September 2021 aktualisiert.</i></p>
              <p>
                <b>LOGENTIS GmbH</b><br />
                Franz-Lenz-Str. 4<br />
                49084 Osnabrück
              </p>

              <p>Geschäftsführer: Andreas Gartemann, Sven Wortmann</p>

              <p>
                Telefon: +49 (0)541 - 5805870<br />
                E-Mail: info@logentis.de<br />
              </p>

              <p>
                Registergericht: Amtsgericht Osnabrück<br />
                Registernummer: HRB 201057<br />
                Umsatzsteuer-IdentNr.: DE814858920
              </p>

              <p>
                Inhaltlich Verantwortliche gemäß § 6 MDStV:
                Andreas Gartemann, Sven Wortmann
              </p>

              <p>
                LOGENTIS Logo Design by Tanja Stiegelmeier<br />
                (RIP - wir werden Dich immer in Erinnerung behalten)
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <aside className="widget-area">
              <div className="widget widget_insight">
                <ul>
                  <li className="active">
                    <Link to="/impressum/">
                      Impressum
                    </Link>
                  </li>
                  <li>
                    <Link to="/agb/">
                      AGB
                    </Link>
                  </li>
                  <li>
                    <Link to="/datenschutz/">
                      Datenschutz
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImpressumContent
